export default {
    application: {
        name: '梦之源管理平台',
    },
    ajaxTip: {
        success: 'Success',
        error: 'Error',
        noAccess: 'Not authorized to access the resource',
        notFound: 'The resource not found',
        serverBusy: 'The server is busy, Try latter',
        timeOut: 'Time out',
    },
    menu: {
        menus: 'Menu & Permission',
        codeGenerator: 'Code Generator',
        home: 'Home',
        document: 'Document',
        page404: '404 Not Found',
        login: 'Log In',
        example: 'Example',
        userCenter: 'User Center',
        users: 'User List',
        userEdit: 'User Edit',
        roles: 'Role List',
        modifyPassword: 'Modify Password',
        setting: 'Setting',
        logout: 'LogOut',
        ajax: 'Ajax',
        antDesign: 'Ant Design',
        google: 'Google',

        component: 'Component',
        tableRowDraggable: 'Table Row Draggable',
        asyncSelect: 'Async Select',
        formElement: 'Form Element',
        formItemLayout: 'Form Item Layout',
        formUtil: 'Form Util',
        inputClear: 'Input Clear',
        listPage: 'List Page',
        modal: 'Modal',
        noData: 'No Data',
        operator: 'Operator',
        pagination: 'Pagination',
        permission: 'Permission',
        popPrompt: 'Pop Prompt',
        queryBar: 'Query Bar',
        queryItem: 'Query Item',
        tableAnimate: 'Table Animate',
        tableDragColumn: 'Table Drag Column',
        tableDragRow: 'Table Drag Row',
        tableEditable: 'Table Editable',
        tableRightClick: 'Table Right Click',
        toolBar: 'Tool Bar',
        userAvatar: 'User Avatar',
    },
    login: {
        title: 'Welcome Login',
        submit: 'Login',
        userName: 'User Name',
        password: 'Password',
        userNameEmptyTip: 'Please Input User Name!',
        passwordEmptyTip: 'Please Input Password!',
    },
    setting: {
        navigationLayout: 'Navigation Layout',
        topSideMenu: 'Top + Left',
        topMenu: 'Top',
        sideMenu: 'Left',
        tabsSetting: 'Tabs Setting',
        tabsShow: 'Tabs Show',
        pageSetting: 'Page Setting',
        showHead: 'Head Show',
        fixedHead: 'Head Fixed',
        menuSetting: 'Menu Setting',
        keepMenuOpen: 'Keep Menu Open',
        keepPage: 'Keep Page Alive',
        fullScreen: 'Full Screen',
        exitFullScreen: 'Exit Full Screen',
        selectPrimaryColor: 'Select Primary Color',
    },
    tabs: {
        refresh: 'Refresh',
        refreshAll: 'Refresh All',
        close: 'Close',
        closeOthers: 'Close Others',
        closeAll: 'Close All',
        closeLeft: 'Close All to the Left',
        closeRight: 'Close All to the Right',
    },
    errorPage: {
        needLogin: 'Need to Log In!',
        pageNotFound: 'Page Not Found!',
        redirectTo: 'You can redirect to',
        orReturn: 'or return',
        previousStep: 'Previous Step',
    },
};
