/*
* 菜单数据 返回Promise各式，支持前端硬编码、异步获取菜单数据
* */
import {getLoginUser} from "./commons";
export default function getMenus(userId) {
  // return Promise.resolve([
//             {key: '/', local: 'home', text: '首页', icon: 'home', path: '/', order: 2000},
//             { key: 'permissionManage', local: 'permission-manage', text: '权限管理', icon: 'play-square', path: '/permission-manage', order: 1300 },
//             {
//                 key: '/permissionManage/systemAdmin',
//                 parentKey: 'permissionManage',
//                 icon: 'user',
//                 local: 'asset',
//                 text: '管理员管理',
//                 path: '/permissionManage/systemAdmin',
//             },
//             {
//                 key: '/permissionManage/permissionList',
//                 parentKey: 'permissionManage',
//                 icon: 'user',
//                 local: 'asset',
//                 text: '权限管理',
//                 path: '/permissionManage/permissionList',
//             },
          
//             {
//                 key: '/permissionManage/roles',
//                 parentKey: 'permissionManage',
//                 icon: 'user',
//                 local: 'asset',
//                 text: '角色管理',
//                 path: '/permissionManage/roles',
//             },
//             {
//                 key: '/permissionManage/deptManage',
//                 parentKey: 'permissionManage',
//                 icon: 'user',
//                 local: 'asset',
//                 text: '部门管理',
//                 path: '/permissionManage/deptManage',
//             },
//             { key: 'authorizationManage', local: 'authorization-manage', text: '授权管理', icon: 'play-square', path: '/authorization-manage', order: 1300 },
//             {
//                 key: '/authorizationManage/deviceManage',
//                 parentKey: 'authorizationManage',
//                 icon: 'user',
//                 local: 'asset',
//                 text: '设备管理',
//                 path: '/authorizationManage/deviceManage',
//             },{
//                 key: '/authorizationManage/applicationManage',
//                 parentKey: 'authorizationManage',
//                 icon: 'user',
//                 local: 'asset',
//                 text: '应用管理',
//                 path: '/authorizationManage/applicationManage',
//             },
//    ]);
      const loginUser = getLoginUser();
      return Promise.resolve(loginUser?loginUser.permissionsList:[]);
    // return Promise.resolve([
    //         {key: '/', local: 'home', text: '首页', icon: 'home', path: '/', order: 2000},
    //         { key: 'permissionManage', local: 'permission-manage', text: '权限管理', icon: 'play-square', path: '/permission-manage', order: 1300 },
    //         {
    //             key: '/permissionManage/roles',
    //             parentKey: 'permissionManage',
    //             icon: 'user',
    //             local: 'asset',
    //             text: '角色管理',
    //             path: '/permissionManage/roles',
    //         }, {
    //             key: '/permissionManage/permissionList',
    //             parentKey: 'permissionManage',
    //             icon: 'user',
    //             local: 'asset',
    //             text: '权限管理',
    //             path: '/permissionManage/permissionList',
    //         },{
    //             key: '/permissionManage/getDepts',
    //             parentKey: 'permissionManage',
    //             icon: 'user',
    //             local: 'asset',
    //             text: '网点管理',
    //             path: '/permissionManage/getDepts',
    //         },
    //         { key: 'livingPayment', local: 'livingPayment', text: '生活激费', icon: 'play-square', path: '/livingPayment', order: 1300 },
    //         {
    //             key: '/permissionManage/livingPaymentColumn',
    //             parentKey: 'livingPayment',
    //             icon: 'user',
    //             local: 'asset',
    //             text: '激费栏目',
    //             path: '/permissionManage/livingPaymentColumn',
    //         } ,
    //         {
    //             key: '/permissionManage/livingPaymentLocation',
    //             parentKey: 'livingPayment',
    //             icon: 'user',
    //             local: 'asset',
    //             text: '激费地点',
    //             path: '/permissionManage/livingPaymentLocation',
    //         } ,
    //         { key: 'bankClassroom', local: 'bankClassroom', text: '金融课堂', icon: 'play-square', path: '/bankClassroom', order: 1270 },
    //         {
    //             key: '/bankClassroom/getClassrooms',
    //             parentKey: 'bankClassroom',
    //             icon: 'user',
    //             local: 'asset',
    //             text: '金融课堂',
    //             path: '/bankClassroom/getClassrooms',
    //         } ,
    //         { key: 'comConfigNavigation', local: 'comConfigNavigation', text: '网点导视', icon: 'play-square', path: '/comConfigNavigation', order: 1270 },
    //         {
    //             key: '/comConfigNavigation/viewNavigationList',
    //             parentKey: 'comConfigNavigation',
    //             icon: 'user',
    //             local: 'asset',
    //             text: '导视管理',
    //             path: '/comConfigNavigation/viewNavigationList',
    //         } ,
    //         { key: 'bankSupermarketConfigType', local: 'bankSupermarketConfigType', text: '金融超市', icon: 'play-square', path: '/bankSupermarketConfigType', order: 1270 },
    //         {
    //             key: '/bankSupermarketConfigType/supermarketTypes',
    //             parentKey: 'bankSupermarketConfigType',
    //             icon: 'user',
    //             local: 'asset',
    //             text: '超市栏目',
    //             path: '/bankSupermarketConfigType/supermarketTypes',
    //         } , {
    //             key: '/bankSupermarketConfigType/getDeposits',
    //             parentKey: 'bankSupermarketConfigType',
    //             icon: 'user',
    //             local: 'asset',
    //             text: '存款管理',
    //             path: '/bankSupermarketConfigType/getDeposits',
    //         } , {
    //             key: '/bankSupermarketConfigType/wealthPlanning',
    //             parentKey: 'bankSupermarketConfigType',
    //             icon: 'user',
    //             local: 'asset',
    //             text: '财富规划',
    //             path: '/bankSupermarketConfigType/wealthPlanning',
    //         } , {
    //             key: '/bankSupermarketConfigType/getInsuranceTypes',
    //             parentKey: 'bankSupermarketConfigType',
    //             icon: 'user',
    //             local: 'asset',
    //             text: '保险栏目',
    //             path: '/bankSupermarketConfigType/getInsuranceTypes',
    //         } ,{
    //             key: '/bankSupermarketConfigType/getInsurances',
    //             parentKey: 'bankSupermarketConfigType',
    //             icon: 'user',
    //             local: 'asset',
    //             text: '保险记录',
    //             path: '/bankSupermarketConfigType/getInsurances',
    //         } , {
    //             key: '/bankSupermarketConfigType/getGoldfundTypes',
    //             parentKey: 'bankSupermarketConfigType',
    //             icon: 'user',
    //             local: 'asset',
    //             text: '金属栏目',
    //             path: '/bankSupermarketConfigType/getGoldfundTypes',
    //         } ,{
    //             key: '/bankSupermarketConfigType/getGoldfunds',
    //             parentKey: 'bankSupermarketConfigType',
    //             icon: 'user',
    //             local: 'asset',
    //             text: '金属记录',
    //             path: '/bankSupermarketConfigType/getGoldfunds',
    //         } ,  {
    //             key: '/bankSupermarketConfigType/fundType',
    //             parentKey: 'bankSupermarketConfigType',
    //             icon: 'user',
    //             local: 'asset',
    //             text: '基金类型',
    //             path: '/bankSupermarketConfigType/fundType',
    //         } ,{
    //             key: '/bankSupermarketConfigType/fundRecord',
    //             parentKey: 'bankSupermarketConfigType',
    //             icon: 'user',
    //             local: 'asset',
    //             text: '基金记录',
    //             path: '/bankSupermarketConfigType/fundRecord',
    //         } ,{
    //             key: '/bankSupermarketConfigType/creditCard',
    //             parentKey: 'bankSupermarketConfigType',
    //             icon: 'user',
    //             local: 'asset',
    //             text: '信用卡记录',
    //             path: '/bankSupermarketConfigType/creditCard',
    //         } ,{
    //             key: '/bankSupermarketConfigType/loan',
    //             parentKey: 'bankSupermarketConfigType',
    //             icon: 'user',
    //             local: 'asset',
    //             text: '贷款',
    //             path: '/bankSupermarketConfigType/loan',
    //         } , 
    //         { key: 'marketingCampaign', local: 'marketingCampaign', text: '营销活动', icon: 'play-square', path: '/marketingCampaign', order: 1270 },
    //         {
    //             key: '/marketingCampaign/marketingActivities',
    //             parentKey: 'marketingCampaign',
    //             icon: 'user',
    //             local: 'asset',
    //             text: '营销活动',
    //             path: '/marketingCampaign/marketingActivities',
    //         } , 
    //         // { key: 'onlinePreferential', local: 'onlinePreferential', text: '线上特惠', icon: 'play-square', path: '/onlinePreferential', order: 1270 },
    //         // {
    //         //     key: '/onlinePreferential/preferentialColumn',
    //         //     parentKey: 'onlinePreferential',
    //         //     icon: 'user',
    //         //     local: 'asset',
    //         //     text: '特惠栏目',
    //         //     path: '/onlinePreferential/preferentialColumn',
    //         // } , 
    //         // {
    //         //     key: '/onlinePreferential/preferentialRecord',
    //         //     parentKey: 'onlinePreferential',
    //         //     icon: 'user',
    //         //     local: 'asset',
    //         //     text: '特惠记录',
    //         //     path: '/onlinePreferential/preferentialRecord',
    //         // } ,
    //          { key: 'interactiveGame', local: 'interactiveGame', text: '互动游戏', icon: 'play-square', path: '/interactiveGame', order: 1270 },
    //         {
    //             key: '/interactiveGame/getGames',
    //             parentKey: 'interactiveGame',
    //             icon: 'user',
    //             local: 'asset',
    //             text: '互动游戏',
    //             path: '/interactiveGame/getGames',
    //         } ,
            
    //         { key: 'shopping', local: 'shopping', text: '善融商城', icon: 'play-square', path: '/shopping', order: 1270 },
    //         {
    //             key: '/shopping/getCategorys',
    //             parentKey: 'shopping',
    //             icon: 'user',
    //             local: 'asset',
    //             text: '商品类型',
    //             path: '/shopping/getCategorys',
    //         } ,{
    //             key: '/shopping/shoppingRecord',
    //             parentKey: 'shopping',
    //             icon: 'user',
    //             local: 'asset',
    //             text: '商品记录',
    //             path: '/shopping/shoppingRecord',
    //         } ,
    //         { key: 'systemSet', local: 'systemSet', text: '系统设置', icon: 'play-square', path: '/systemSet', order: 1270 },
    //         {
    //             key: '/systemSet/bankInfoSet',
    //             parentKey: 'systemSet',
    //             icon: 'user',
    //             local: 'asset',
    //             text: '银行信息设置',
    //             path: '/systemSet/bankInfoSet',
    //         } ,
    //         {
    //             key: '/systemSet/elasticFrameQrCode',
    //             parentKey: 'systemSet',
    //             icon: 'user',
    //             local: 'asset',
    //             text: '弹框二维码',
    //             path: '/systemSet/elasticFrameQrCode',
    //         } 
    // ]);
}
