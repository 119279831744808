import { sxAjax } from '@/commons/ajax';
export default {
    initialState: {
        loading: false,             // 全局loading
    },
    // 列表
    dataList: {
        payload: ({ params, options }) => sxAjax.get('/admin/comConfigApiKey/getApiKeys', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
            },
            complete: (state, action) => ({ loading: false }),
        }
    }, // 列表
    addApiKey: {
        payload: ({ params, options }) => sxAjax.post('/admin/comConfigApiKey/addApiKey', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
            },
            complete: (state, action) => ({ loading: false }),
        }
    }
    , // 编辑
    editApiKey: {
        payload: ({ params, options }) => sxAjax.post('/admin/comConfigApiKey/editApiKey', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
            },
            complete: (state, action) => ({ loading: false }),
        }
    }, // 删除
    delApiKey: {
        payload: ({ params, options }) => sxAjax.get('/admin/comConfigApiKey/delApiKey', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
            },
            complete: (state, action) => ({ loading: false }),
        }
    }, // 获取已绑定设备
    getBindDevicesRel: {
        payload: ({ params, options }) => sxAjax.get('/admin/comConfigApiKey/getBindDevicesRel', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
            },
            complete: (state, action) => ({ loading: false }),
        }
    }, //添加关联设备
    addApiKeyDeviceRel: {
        payload: ({ params, options }) => sxAjax.post('/admin/comConfigApiKey/addApiKeyDeviceRel', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
            },
            complete: (state, action) => ({ loading: false }),
        }
    },//解除绑定
    relieveApiKeyDeviceRel: {
        payload: ({ params, options }) => sxAjax.post('/admin/comConfigApiKey/relieveApiKeyDeviceRel', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
            },
            complete: (state, action) => ({ loading: false }),
        }
    },
}