// 此文件是通过脚本生成的，直接编辑无效！！！

// 不需要导航框架的页面路径
export const noFrames = [
    '/login',
];

// 不需要登录就可以访问的页面路径
export const noAuths = [
    '/login',
];

// 需要keep alive 页面
export const keepAlives = [
    {
        path: '/iframe_page_/:src',
        keepAlive: true,
    },
    {
        path: '/login',
        keepAlive: false,
    },
];

// 页面路由配置
export default [
    {
        path: '/permissionManage/agentAdmin',
        component: () => import('F:/web/工具后台/src/pages/admin-manage/agent-admin/index.jsx'),
    },
    {
        path: '/administratorsManage/dotAdmin',
        component: () => import('F:/web/工具后台/src/pages/admin-manage/dot-administrators/index.jsx'),
    },
    {
        path: '/permissionManage/roleManage',
        component: () => import('F:/web/工具后台/src/pages/admin-manage/role-manage/index.jsx'),
    },
    {
        path: '/permissionManage/systemAdmin',
        component: () => import('F:/web/工具后台/src/pages/admin-manage/system-administrators/index.jsx'),
    },
    {
        path: '/apiKeyManage/apiKeyManage',
        component: () => import('F:/web/工具后台/src/pages/apikey-manage/index.jsx'),
    },
    {
        path: '/authorizationManage/applicationManage',
        component: () => import('F:/web/工具后台/src/pages/application-manage/index.jsx'),
    },
    {
        path: '/authorizationManage/deviceManage',
        component: () => import('F:/web/工具后台/src/pages/device-manage/index.jsx'),
    },
    {
        path: '/',
        component: () => import('F:/web/工具后台/src/pages/home/index.jsx'),
    },
    {
        path: '/iframe_page_/:src',
        component: () => import('F:/web/工具后台/src/pages/iframe/index.jsx'),
    },
    {
        path: '/login',
        component: () => import('F:/web/工具后台/src/pages/login/index.jsx'),
    },
    {
        path: '/permissionManage/deptManage',
        component: () => import('F:/web/工具后台/src/pages/organization/index.jsx'),
    },
    {
        path: '/permissionManage/permissionList',
        component: () => import('F:/web/工具后台/src/pages/privilege-manage/permissions/index.jsx'),
    },
    {
        path: '/permissionManage/roles',
        component: () => import('F:/web/工具后台/src/pages/privilege-manage/roles/index.jsx'),
    },
    {
        path: '/permissionManage/getDepts',
        component: () => import('F:/web/工具后台/src/pages/privilege-manage/website/index.jsx'),
    },
    {
        path: '/dingTalkRobotManage/robotManage',
        component: () => import('F:/web/工具后台/src/pages/robot-manage/index.jsx'),
    },
    {
        path: '/user-center',
        component: () => import('F:/web/工具后台/src/pages/user-center/UserCenterList.jsx'),
    },
];
