/**
 * 去掉为空的对象属性
 */
export function removeNullValue(obj) {
    const newObj = {};
    if (obj && !Array.isArray(obj) && typeof obj === 'object') {
        Object.keys(obj).forEach(key => {
            const value = obj[key];
            if (value !== undefined && value !== null && value !== '' && value !== void 0) {
                newObj[key] = value;
            }
        })
    }
    return newObj;
};
/**
 * 拼接字符串  用于get请求时候的url拼接  传进来obj={}对象
 */
export function concatenateString(obj) {
    const o = removeNullValue(obj);
    let urlStr = '';
    Object.keys(o).forEach(key => {
        const value = o[key];
        if (value !== undefined && value !== null && value !== '' && value !== void 0) {
            urlStr += '&' + key + '=' + o[key];
        }

    })
    return urlStr;
}



/**
 * 用于判断对象是不是为空对象:{},123,"",[]等都返回true {'sdf':''}返回false
 * 是空对象返回true  否则返回false
 */

export function isEmptyObject(e) {
    var t;
    for (t in e)
        return !1;
    return !0
}
/**
 * 用于判断字符串是不是为空
 */
export function isEmptyStr(obj) {
    if (typeof obj === "undefined" || obj === null || obj === "") {
        return true;
    } else {
        return false;
    }
}