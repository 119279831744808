import { sxAjax } from '@/commons/ajax';
export default {
    // 列表
    getApplications: {
        payload: ({ params, options }) => sxAjax.get('/admin/proxy/getAuthApplications', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve (state, { payload = {} }) {
            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    addApplication: {
        payload: ({ params, options }) => sxAjax.post('/admin/proxy/addProxyApplication', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve (state, { payload = {} }) {
            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    getEditLog: {
        payload: ({ params, options }) => sxAjax.post('/admin/proxy/getupdateActivateNumLog', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve (state, { payload = {} }) {
            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    editApplication: {
        payload: ({ params, options }) => sxAjax.post('/admin/proxy/updateActivateNum', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve (state, { payload = {} }) {
            },
            complete: (state, action) => ({ loading: false }),
        }
    },
    delApplication: {
        payload: ({ params, options }) => sxAjax.post('/admin/proxy/delProxyApplication', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve (state, { payload = {} }) {
            },
            complete: (state, action) => ({ loading: false }),
        }
    }
}
