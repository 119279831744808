import React, { Component } from 'react';
import { Menu, Dropdown, Icon, message, Form, Modal, Row, Col } from 'antd';
import { toLogin, getLoginUser } from '@/commons';
import { connect } from '@/models';
import { UserAvatar } from '@/library/antd';
import { isEmptyObject } from '@/commons/dataUtil.js';
import { FormElement } from '@/library/antd';
import config from '@/commons/config-hoc';
import './style.less';

const Item = Menu.Item;

@connect(state => ({ local: state.system.i18n }))
export default class HeaderUser extends Component {
    static defaultProps = {
        theme: 'default',
    };
    state = {
        updatePasswordVisible: false,
        aboutUsVisible: false,
        courseVisible: false,
        leavingMessageVisible: false
    };

    handleMenuClick = ({ key }) => {
        if (key === 'logout') {
            // TODO 发送请求退出登录
            toLogin();
        }

        if (key === 'modifyPassword') {
            this.setState({ updatePasswordVisible: true });
        }
    };


    handleOk = () => {

    };
    render() {
        const user = getLoginUser() || {};
        const name = user.name;
        const avatar = user.avatar;

        const { className, theme, local } = this.props;

        const menu = (
            <Menu styleName="menu" theme={theme} selectedKeys={[]} onClick={this.handleMenuClick}>
                <Item key="modifyPassword"><Icon type="edit" />{local.menu.modifyPassword}</Item>
                <Item key="logout"><Icon type="logout" />{local.menu.logout}</Item>
            </Menu>
        );
        return (
            <div styleName="user-menu" ref={node => this.userMenu = node}>
                <Dropdown overlay={menu} getPopupContainer={() => (this.userMenu || document.body)}>
                    <span styleName="account" className={className}>
                        {avatar ? (
                            <UserAvatar size="default" styleName="avatar" src={avatar} />
                        ) : (
                                <UserAvatar size="default" styleName="avatar" name={name} />
                            )}
                        {name}
                        <Icon type="caret-down" />
                    </span>
                </Dropdown>
                <UploadPwd
                    visible={this.state.updatePasswordVisible}
                    onOk={() => this.setState({ updatePasswordVisible: false })}
                    onCancel={() => this.setState({ updatePasswordVisible: false })}
                ></UploadPwd>
            </div>
        );
    }
}


@config({
    ajax: true,
    connect(state) {
    }
})
@Form.create()
class UploadPwd extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataSource: [],
            dataListSpinLoading: false,
            //图片设置
            categoryDataSource: [],
            //视频设置
            loading: false,
        };
    }

    handleCancel = () => {
        const { onCancel } = this.props;
        if (onCancel) onCancel();
    };

    handleOk = () => {
        const { loading } = this.state;
        if (loading) return;
        const { onOk, form: { validateFieldsAndScroll } } = this.props;

        validateFieldsAndScroll((err, values) => {
            if (!err) {
                const newPwd = values.newPwd;
                const oncePwd = values.oncePwd;
                if (newPwd !== oncePwd) {
                    message.info('新密码与确认密码不一样!');
                    return;
                }
                this.setState({ loading: true });
                const params = {
                    id: getLoginUser().id,
                    oldPassword: this.$md5(values.oldPwd),
                    newPassword: this.$md5(values.newPwd),
                };
                console.log(params);
                this.props.action.system.updatePassword({
                    params: params, options: { method: "POST", withCredentials: false, contentType: "application/json" },
                    onResolve: (data) => {
                        onOk()
                        message.info("操作成功！")
                    },
                    onReject: (data) => {
                        message.info(data);
                    }
                });

            }
            this.setState({ loading: false });
        });
    };



    FormElement = (props) => <FormElement form={this.props.form} labelWidth={100} {...props} />;
    render() {
        const { visible, itemObj } = this.props;
        const { loading } = this.state;
        const FormElement = this.FormElement;
        return (
            <Modal
                destroyOnClose
                width="45%"
                confirmLoading={loading}
                visible={visible}
                title={isEmptyObject(itemObj) ? "添加" : '编辑'}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
            >
                <Form>
                    <Row>
                        <Col span={17}>
                            <FormElement
                                label="原密码"
                                field="oldPwd"
                                type="password"
                                decorator={{
                                    initialValue: undefined,
                                    rules: [
                                        { required: true, message: '请输入原密码！' }
                                    ],
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={17}>
                            <FormElement
                                label="新密码"
                                field="newPwd"
                                type="password"
                                decorator={{
                                    initialValue: undefined,
                                    rules: [
                                        { required: true, message: '请输入新密码！' }
                                    ],
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={17}>
                            <FormElement
                                label="确认密码"
                                field="oncePwd"
                                type="password"
                                decorator={{
                                    initialValue: undefined,
                                    rules: [
                                        { required: true, message: '请确认密码！' }
                                    ],
                                }}
                            />
                        </Col>
                    </Row>

                </Form>

            </Modal>
        );
    }
}