import { sxAjax } from '@/commons/ajax';
export default {
  //获取路由菜单
    getRouters: {
        payload: ({ params, options }) => sxAjax.get('/admin/permission/getReactRouters', params, options).then(function (resp) {
            return new Promise((resolve, reject) => {
                if (resp.code === 0) {
                    resolve(resp.data);
                } else {
                    reject(resp.msg);
                }
            });
        }),
        reducer: {
            pending: (state, action) => {
                return { loading: true };
            },
            resolve(state, { payload = {} }) {
            },
            complete: (state, action) => ({ loading: false }),
        }
    },

}