// 此文件为生成文件，不要直接编辑
import _administratorsManage from 'F:/web/工具后台/src/models/administratorsManage.js';
import _adminList from 'F:/web/工具后台/src/models/adminList.js';
import _agentAdmin from 'F:/web/工具后台/src/models/agentAdmin.js';
import _apiKeyManage from 'F:/web/工具后台/src/models/apiKeyManage.js';
import _applicationManage from 'F:/web/工具后台/src/models/applicationManage.js';
import _common from 'F:/web/工具后台/src/models/common.js';
import _deptManage from 'F:/web/工具后台/src/models/deptManage.js';
import _deviceManage from 'F:/web/工具后台/src/models/deviceManage.js';
import _dingTalkRobotManage from 'F:/web/工具后台/src/models/dingTalkRobotManage.js';
import _dotOrientation from 'F:/web/工具后台/src/models/dotOrientation.js';
import _home from 'F:/web/工具后台/src/models/home.js';
import _menu from 'F:/web/工具后台/src/models/menu.js';
import _page from 'F:/web/工具后台/src/models/page.js';
import _permissions from 'F:/web/工具后台/src/models/permissions.js';
import _roles from 'F:/web/工具后台/src/models/roles.js';
import _routers from 'F:/web/工具后台/src/models/routers.js';
import _settings from 'F:/web/工具后台/src/models/settings.js';
import _side from 'F:/web/工具后台/src/models/side.js';
import _system from 'F:/web/工具后台/src/models/system.js';
import _usercenter from 'F:/web/工具后台/src/models/usercenter.js';
import _website from 'F:/web/工具后台/src/models/website.js';

export const administratorsManage = _administratorsManage;
export const adminList = _adminList;
export const agentAdmin = _agentAdmin;
export const apiKeyManage = _apiKeyManage;
export const applicationManage = _applicationManage;
export const common = _common;
export const deptManage = _deptManage;
export const deviceManage = _deviceManage;
export const dingTalkRobotManage = _dingTalkRobotManage;
export const dotOrientation = _dotOrientation;
export const home = _home;
export const menu = _menu;
export const page = _page;
export const permissions = _permissions;
export const roles = _roles;
export const routers = _routers;
export const settings = _settings;
export const side = _side;
export const system = _system;
export const usercenter = _usercenter;
export const website = _website;

