import React, { Component } from 'react';
import { connect } from '../../models';
import './style.less';
@connect(state => ({
    local: state.system.local,
}))
export default class HeaderUser extends Component {
    static defaultProps = {
        theme: 'default',
    };

    handleMenuClick = ({ key }) => {
        this.props.action.system.setLocal(key);
    };

    render() {
        const { local } = this.props;
        console.log(local)
        return (
            <div
            >
                {/* <Dropdown
                    overlay={menu}
                    getPopupContainer={() => (this.root || document.body)}
                >
                    <span styleName="i18n-label" className={className}>
                        <span>{localI1n8.name}</span>
                        <Icon type="caret-down"/>
                    </span>
                </Dropdown> */}
            </div>
        );
    }
}
